<template>
    <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" @load="onLoad(page)">
                <div v-if="list.length">
                    <div class="new_card" v-for="(item,lindex) in list" :key="item.index">
                        <div class="new_card_on" @click="goRecruitmentDetails(item.recruitment_id)">
                            <div class="new_card_on_lt"><img style="width:100%;height:100%" :src="item.factory_img? item.factory_img : morenurl" /></div>
                            <div class="new_card_on_rt">
                                <div class="new_card_on_rt_cd1">
                                    <div class="new_card_on_rt_cd1_lt">
                                        {{
                                                item.factory_short_name
                                                    ? item.factory_short_name
                                                    : item.factory_name
                                            }}
                                    </div>
                                    <div v-if="item.colleoct==1" class="wageright" @click.stop="deletecollect(item.recruitment_id,lindex,2)">
                                        <div class="iconfont icon-yishoucang"></div>
                                        <div class="wageright_text">已收藏</div>
                                    </div>
                                    <div v-if="item.colleoct==2" class="wageright" @click.stop="shouchangCollect(item.recruitment_id,lindex,2)">
                                        <div class="iconfont icon-shoucang"></div>
                                        <div class="wageright_text">收藏</div>
                                    </div>
                                </div>
                                <div class="new_card_on_rt_cd2">{{ item.salary_for_display? item.salary_for_display: item.wage_for_display }}</div>
                                <div class="new_card_on_rt_cd3" v-if="item.tag_name_list.length">
                                    <div class="new_card_on_rt_cd3_on" v-for="(tag,
                                                tag_key) in item.tag_name_list.slice(
                                                    0,
                                                    3
                                                )" :key="tag_key">
                                        {{ tag }}</div>
                                </div>
                                <div class="new_card_on_rt_cd4">{{item.job_name}}</div>
                            </div>
                        </div>
                        <div class="new_card_shixiao" v-if="item.is_close==1">
                            <div class="new_card_shixiao_on">
                                <div class="new_card_shixiao_on_text">职位已关闭</div>
                                <div class="new_card_shixiao_on_wenzi" @click.stop="deletecollect(item.recruitment_id,lindex,1)">x</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-else class="null-data block">
                    <div>
                        <img :src="src + 'null_index.png'" />
                        <div>暂无收藏</div>
                    </div>
                </div>
                <!-- -->
                <div class="bottom_back" style="position: relative;">
                    <div class="bottom_back_on" @click="gobanck">
                        <div class="bottom_back_wenz">返回</div>
                        <div class="iconfont icon-fanhui"></div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>

    </div>
</template>
<script>
import "@/assets/css/index.css";
import config from "@/utils/config.js";
import { Dialog, Icon } from "vant";
export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Icon.name]: Icon,
    },
    data() {
        return {
            // 分页=======================================
            loading: false,
            finished: false,
            refreshing: true,
            morenurl: require('../../assets/morencaifeng.png'),
            page: 1, //请求第几页
            total: 0, //总共的数据条数
            // ==========================================
            show_down: false, // 是否展示下拉选项
            src: `${config.ossImageUrl}`,
            list: [

            ],
            imgList: [],
            is_click: false, // 是否点击过了排序
            latitude: "22.57365",
            longitude: "113.910965",
            sort_select: 0, // 排序方式   0为综合排序   1为工价排序正序    2为工价排序降序
            is_screen: 0, // 是否有筛选条件
            screen: {}, // 筛选的列表  不会改变的  跳转到筛选页面后的使用
            screenValue: {}, // 筛选的值   会改变的  跳转到筛选页面后的使用
            keySearchFalse: "", // 假的关键字，没点击搜索前不生效
            search_key: "", // 搜索的关键字

        };
    },
    mounted() {
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        this.loading = false;
        this.getList()
    },
    methods: {
        gobanck() {
            // this.$router.go(-1);
            this.$router.replace('/home')
        },
        // 跳转到招工详情
        goRecruitmentDetails(id) {
            // 这里是登录
            this.$router.push({
                path: "/recruitmentDetails",
                query: { rid: id, type: 'apply' },
            });
        },
        //
        shouchangCollect(id, index) {
            let data = {
                recruitment_id: id,
                team_id: localStorage.getItem('team_id') || 0
            }
            this.Request('client/recruitment/favorites', 'post', data).then(res => {
                if (res.status == 0) {
                    this.list[index].colleoct = 1;
                }
            })
        },
        //
        deletecollect(id, index, type) {
            let data = {
                recruitment_id: id
            }
            this.Request('client/recruitment/favorites', 'delete', data).then(res => {
                if (res.status == 0) {
                    if (type == 1) {
                        this.list.splice(index, 1)
                        this.$toast({
                            duration: 2000,
                            message: '该招工信息已删除',
                        });
                    } else {
                        this.list[index].colleoct = 2;
                    }

                }
                //   this.list =this.list;
            })
        },
        //
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.getList();
        },
        // 调接口，获取列表数据
        getList(page = 1) {
            if (page == 1) this.list = [];
            if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
            }
            this.$store.state.refreshList = false;
            var data = {
                team_id: localStorage.getItem("team_id"),
                limits: 20,
                page: page,
                longitude: this.longitude,
                latitude: this.latitude
            };

            this.Request('client/recruitment/favorites', 'get', data)
                .then((res) => {
                    if (res.status == 0) {
                        this.page++;
                        let resultList = res.data.list
                        resultList.forEach(tem => {
                            tem.colleoct = 1;
                        })
                        this.list = this.list.concat(
                            resultList
                        );
                        this.loading = false;
                        if (this.list.length >= res.data.count) {
                            this.finished = true;
                        }
                    }
                })
                .catch((err) => {
                    this.$toast({
                        duration: 2000,
                        message: `${err.msg}`,
                    });
                });
        },
        
        onLoad(page) {
            this.finished = false;
            //this.loading = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            // this.getList()
            if (page != 1) {
                this.getList(page);
            }
        },
    },
}
</script>
<style scoped>
.new_card_shixiao_on_wenzi {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    color: #c1c1c1;
    text-align: center;
    position: absolute;
    right: 30px;
    bottom: 30px;
}
.new_card_shixiao_on_text {
    background-color: #e5e5e5;
    color: #999999;
    width: 21%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    font-size: 16px;
    transform: rotate(45deg);
    top: 22px;
    right: -36px;
}
.new_card_on_rt {
    width: calc(100% - 250px);
    height: 176px;
}
.new_card_on_rt_cd1 {
    width: 100%;
    /* height: 45px; */
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.new_card_on_rt_cd1_lt {
    width: calc(100% - 135px);
    /* height: 45px; */
    /* line-height: 45px; */
    text-align: left;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 10px;
    /* margin-top: 10px; */
    color: #1a1a1a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd1_rt {
}
.new_card_on_rt_cd2 {
    width: 100%;
    /* height: 40px; */
    /* line-height: 40px; */
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0abbb9;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd3 {
    width: 100%;
    /* height: 30px; */
    margin-bottom: 12px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.new_card_on_rt_cd3_on {
    margin-right: 5px;
    min-width: 10px;
    padding: 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
    /* line-height: 30px; */
    text-align: center;
    background: #f8f8f8;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999;
    border-radius: 4px;
}
.new_card_on_rt_cd4 {
    width: 100%;
    /* height: 30px; */
    /* line-height: 30px; */
    text-align: left;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.new_card_on_lt {
    width: 230px;
    height: 176px;
    border-radius: 4px;
    overflow: hidden;
}
.new_card_on {
    width: calc(100% - 60px);
    height: 176px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.new_card {
    width: 100%;
    height: 240px;
    background: #ffffff;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.new_card_shixiao {
    width: 100%;
    height: 240px;
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    left: 0;
    top: 0;
    z-index: 10;
}
.new_card_shixiao_on {
    width: 100%;
    height: 240px;
    position: relative;
    background: rgba(255, 255, 255, 0);
    overflow: hidden;
}

/*  */
.zhiwguoqi {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.zhiwguoqi_on {
    width: calc(100% - 60px);
    height: 32px;
    display: flex;
    flex-wrap: nowrap;
}
.zhiwguoqi_on_lt {
    width: calc(100% - 160px);
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-left: 80px;
}
.zhiwguoqi_on_rt {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 28px;
    background: #ff2c2c;
    border-radius: 4px;
    color: #ffffff;
}
.wageright {
    color: #f8842c;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.wageright .iconfont {
    margin-right: 4px;
    margin-top: -4px;
}
.wageright_text {
    height: 0.3rem;
    line-height: 0.3rem;
    text-align: right;
}
.wageright_img {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 10px;
}
/* 头部固定 */
.top {
    position: fixed;
    width: 100%;
    z-index: 2;
    /* margin-top: 90px; */
}

/* 列表里的搜索框 */
.search {
    margin-top: -2px;
    width: 55%;
}
.search .search_text {
    display: flex;
    border: 2px solid #ddd;
    border-radius: 50px;
    padding: 10px 20px;
    line-height: 40px;
}
.van-overlay {
    z-index: 2;
}
.down {
    font-size: 20px;
    transform: rotate(90deg);
    margin-left: -0.14rem;
    margin-bottom: 0.2rem;
}
.down_open_content {
    background: #fff;
    padding: 0 40px;
    line-height: 48px;
}
.down_open_content > .displayFlex {
    border-bottom: 1px solid #ebedf0;
    padding: 20px 0;
}
.down_open_content > .displayFlex > i {
    line-height: 48px;
}
.down_open_content > .displayFlex:last-of-type {
    border: 0;
}
.search .search_text input {
    width: 84%;
    background: transparent;
    border: 0;
}

.search .search_text img {
    width: 32px;
    height: 32px;
    margin: 12px 20px 0 0;
}
.iconfont {
    font-size: 30px;
    margin-top: 2px;
    font-weight: 700;
}
/* 条件筛选 */
.sort {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    background-color: #fff;
    padding: 20px 40px;
}
.sort_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sort_icon > div {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin: 3px;
}
.sort_icon .sort_top {
    border-bottom: 10px solid #ccc;
}
.sort_icon .sort_bottom {
    border-top: 10px solid #ccc;
}
.screen {
    width: 40px;
    height: 40px;
}
.sort_title {
    margin-right: 10px;
}
.sort-select {
    font-weight: 700;
}
.sort .img {
    width: 40px;
    height: 40px;
}
/* 卡片 */
.block {
    position: relative;
    top: 120px;
    padding-bottom: 1.33333rem;
}
.card {
    background-color: #fff;
    padding: 20px 30px;
    margin-bottom: 20px;
    height: 128px;
    align-items: center;
}
.card .logo {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}
.card .info {
    margin-left: 10px;
    width: calc(100% - 120px);
}
.card .info > div {
    min-height: 20px;
}
.card .com_name {
    font-weight: 700;
    margin-right: 10px;
    font-size: 32px;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.card .tips {
    border: 2px solid #666666;
    border-radius: 5px;
    color: #666666;
    display: inline-table;
    padding: 0 6px;
    font-size: 20px;
    height: 32px;
    line-height: 32px;
    margin-top: 5px;
}
.card .tips.redcolor {
    border: 2px solid #ff2c2c;
    color: #ff2c2c;
}
.card .address {
    font-size: 28px;
}
.card .wage {
    font-weight: 700;
    font-size: 28px;
    margin: 0 10px;
}

/* 不展示 */
.tips.color10 {
    color: #999999;
    border-color: #999999;
}
/* 已招满 */
.tips.color20 {
    color: #27b465;
    border-color: #27b465;
}
/* 招聘中 */
.tips.color30 {
    color: #4c94f6;
    border-color: #4c94f6;
}
/* 热招 */
.tips.color40 {
    color: #f8842c;
    border-color: #f8842c;
}
/* 急招 */
.tips.color50 {
    color: #ff2c2c;
    border-color: #ff2c2c;
}

.van-pull-refresh {
    overflow: initial;
}
.my-swipe {
    height: 250px;
}
.my-swipe img {
    height: 250px;
    background: #fff;
}
.top446 {
    top: 370px;
}
.top220 {
    top: 120px;
}
.news_small {
    min-width: 10px;
    height: 32px;
    line-height: 32px;
    color: #4c94f6;
    background: #dbeafd;
    text-align: center;
    font-size: 20px;
    padding: 0 10px;
    margin-right: 10px;
    /* margin: 10px 0 10px 0; */
}
/* .news_small_left {
    position: relative;
    margin-left: 20px;
}
.news_small_left::after {
    display: block;
    content: "";
    position: absolute;
    left: -9px;
    top: 6px;
    width: 1px;
    height: 15px;
    background: #999999;
} */
</style>